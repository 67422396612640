// create empty div component
import React, {useState} from "react"

const FORMSPARK_ACTION_URL = "https://submit-form.com/hgf0JZYW"
const RequestDemo = ({formName, planType="none"}) => {
  const [successAlert, setSuccessAlert] = useState(false)
  const [FormData, setFormData] = useState({})
  const handleChange = e => setFormData({...FormData, [e.target.name]: e.target.value })
  return (<>
    {/** request demo form */}
    { !successAlert ? (<form
      name={formName}
      method="post"
      action={FORMSPARK_ACTION_URL}
      className="mt-5 p-5" onChange={handleChange}>
      <input type="hidden" name="planType" value={planType}/>
      <input type="hidden" name="formName" value={formName}/>
      <h3>Изпратете заявка</h3>
      <div className="form-group mb-2">
        <label htmlFor="email" className="sr-only">
                Email
        </label>
        <input
          required
          type="email"
          className="form-control"
          name="email"
          placeholder="Вашият имейл"
        />
      </div>
      {/** name of your business */}
      <div className="form-group mb-2">
        <label htmlFor="firm" className="sr-only">
                Име на вашият бизнес
        </label>
        <input
          required
          type="text"
          className="form-control"
          name="firm"
          placeholder="Име на вашият бизнес"
        />
      </div>
      {/*Full Name*/}
      <div className="form-group mb-2">
        <label htmlFor="name" className="sr-only">
                Име и фамилия
        </label>
        <input
          required
          type="text"
          className="form-control"
          name="name"
          placeholder="Име и фамилия"
        />
      </div>
      {/*Job title*/}
      <div className="form-group mb-2">
        <label htmlFor="jobTitle" className="sr-only">
                Длъжност
        </label>
        <input
          required
          type="text"
          className="form-control"
          name="jobTitle"
          placeholder="Длъжност"
        />
      </div>
      {/** phone number */}
      <div className="form-group mb-2">
        <label htmlFor="phone" className="sr-only">
                Телефон
        </label>
        <input
          required
          type="text"
          className="form-control"
          name="phone"
          placeholder="Телефон"
        />
      </div>
      {/** additional info */}
      <div className="form-group mb-2">
        <label htmlFor="additionalInfo" className="sr-only">
                Допълнителна информация (не е задължително)
        </label>
        <textarea
          name="additionalInfo"
          className="form-control"
          rows="1"
          placeholder="Допълнителна информация"
        ></textarea>
      </div>
      {/** submit button */}
      <button type="submit" className="btn btn-primary mb-2 text-white">
              Изпрати заявка
      </button>
    </form>) : (
      <div className="alert alert-primary" role="alert">
              Благодарим Ви! Вашата заявка е изпратена успешно.
      </div>
    )
    }
  </>
  )
}
export default RequestDemo