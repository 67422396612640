import { navigate } from "gatsby"
import React, {useState} from "react"
import Layout from "../../components/layout"
import RequestDemo from "../../components/requestDemo"
import SEO from "../../components/seo"

const ServicesForRestaurantPage = () => {
  const [planType, setPlanType] = useState("")
  const handleClick = (e) => {
    e.preventDefault()
    const button = e.target
    setPlanType(button.dataset.plantype)
    navigate("#request-demo")
  }
  return (
    <Layout>
      <SEO title="За заведения - Услуги"/>
      <div className='container'>
        <h1>Услуги за заведения (ресторанти, клубове, барове, кафенета и др.)</h1>
        <section>
          <div className="ratio ratio-16x9">
            <iframe src="https://www.youtube.com/embed/4OySavgx7MU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </section>
        <section className="container my-5 py-2" id="restaurants">
          <h2>Професионална изработка на уебсайт </h2>
          <h3 className="text-center mt-4 py-3">Еднократни пакети</h3>
          <div className="card-group mb-3 text-center">
            <div className="card mb-4 box-shadow">
              <div className="card-header">
                <h4 className="my-0 fw-normal">Стандарт</h4>
              </div>
              <div className="card-body">
                <ul className="list-unstyled mt-3 mb-4">
                  <li>включва всичко необходимо за бърз старт на бизнеса</li>
                  <li>статично съдържание</li>
                  <li>бързозареждащ и модерен сайт</li>
                  <li>адаптивен дизайн за мобилни устройства</li>
                  <li>поддръжка на различни езици</li>
                  <li>меню с подходящ дизайн</li>
                  <li>галерия снимки</li>
                  <li>Безплатен домейн за 1 година <sup>1</sup></li>
                  <li>форма или връзка за контакт</li>
                  <li>връзка със социални мрежи</li>
                  <li>SEO <sup>2</sup></li>
                  <li>възможност за лесен ъпгрейд до пакета Златен - до 6 месеца само доплащате разликата</li>
                </ul>
                <button className="btn btn-lg btn-block btn-outline-cobalt" data-plantype="standard" onClick={handleClick}>Изпрати заявка</button>
              </div>
            </div>

            <div className="card mb-4 box-shadow border border-5 border-primary">
              <div className="card-header">
                <h4 className="my-0 fw-normal">Златен <span className="badge bg-cobalt">Препоръчано</span></h4>
              </div>
              <div className="card-body">
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Включва всичко от пакета Стандарт</li>
                  <li>Меню, менажирано от ресторанта през Google Sheets</li>
                  <li>Актуализиране на съдържанието на сайта с натискането на бутон &quot;Публикувай промените&quot;</li>
                  <li>Платформа за съхранение, редактиране и управление на снимки, лесна за използване</li>
                  <li>възможност за лесен ъпгрейд до пакета Премиум - до 6 месеца само доплащате разликата</li>
                </ul>
                <button className="btn btn-lg btn-block btn-cobalt" data-planType="gold" onClick={handleClick}>Изпрати заявка</button>
              </div>
            </div>

            <div className="card mb-4 box-shadow">
              <div className="card-header">
                <h4 className="my-0 fw-normal">Премиум</h4>
              </div>
              <div className="card-body">
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Включва всичко от пакета Златен</li>
                  <li>Онлайн поръчки</li>
                  <li>Регистрация в Google Maps (опция)</li>
                  <li>Регистрация в локални бизнес директории (опция)</li>
                  <li>Дългосрочна SEO стратегия</li>
                  <li>Блог</li>
                  <li>Google Docs интеграция за новини</li>
                </ul>
                <button className="btn btn-lg btn-block btn-outline-cobalt" data-planType="premium" onClick={handleClick}>Изпрати заявка</button>
              </div>
            </div>
          </div>
          <h4>Бележки</h4>
          <p>1 Важи само за .bg или .com домейни, за други се обсъждат допълнително</p>
          <p>2 SEO - оптимизация за търсачки</p>
          <h3 className="text-center mt-4 py-3">Месечни пакети</h3>
          <p className="text-center">Хостинг и поддръжка</p>
        </section>
        <section id="request-demo">
          <RequestDemo formName="restaurant-request-demo" planType={planType}/>
        </section>
        <section className='py-3'>
          <h3 className="text-center mt-4 py-3">Допълнителни услуги</h3>
          <h4>SEO</h4>
          <p>Поддържане на сайта Ви в най-предните позиции при резултатите от търсене.</p>
          <p>Позволете повече клиенти да Ви намерят.</p>
          <h4>Социални мрежи</h4>
          <p>Aнгажиране на клиентите с редовно публикуване в двете най-популярни платформи - Facebook и Instagram.</p>
        </section>
        <span href="#request-demo" className="btn btn-lg btn-block btn-outline-cobalt">Изпрати заявка</span>
      </div>
    </Layout>)
}

export default ServicesForRestaurantPage